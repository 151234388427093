@use "./config/" as *;
@forward './main.scss';
@forward './module/static';
@forward './module/static/';
@forward './module/banner';
@forward './events/fourstar-shoswcase';
@forward './events/eventstrip';
@forward './fixtures/schedule';
@forward './swiper/swiper-bundle';
.waf-development {
    @extend %development-page-head-bg;
    .layout-wrapper{
        max-width: unset;
    }
    .head-wrap {
        margin-block: 0;
    }
    .waf-head {
        &::before {
            content: unset;
        }
    }
    .waf-body {
        margin-top: -9rem;
        @include default-wrapper();
    }
}
.waf-documents {
    @extend %document-link;
    .card-list{
        .card-content{
            word-break: break-word;
        }
    }
}
@include mq(col-tablet) {
    .waf-for-athletes {
        &.waf-common-static {
            .card {
                &-item {
                    align-items: flex-start;
                    .card-label {
                        margin-block: 0;
                        line-height: 1;
                    }
                    &.training-tips {
                        .card-head {
                            padding-block: var(--space-0);
                        }
                    }
                }
                &-head {
                    padding-block: 0;
                }
            }
        }
        .accordion-item {
            &:not(:last-child) {
                margin-bottom: var(--space-4);
                .accordion-body {
                    margin-bottom: 0;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .wtc-page {
        .waf-contact {
            .waf-head {
                height: 37rem;
            }
        }
    }
}
