@use '../config/' as *;
.waf-schedule {
  padding-block: var(--space-12) var(--space-20);
  .layout-wrapper {
    @include large-wrapper();
  }
  .waf {
    &-head {
      @extend %mb-8;
    }
    &-body {
      display: grid;
      grid-template-columns: 100%;
      @extend %gap-6;
    }
    &-content-wrapper {
      @extend %p-10-4;
      @extend %border-radius-m;
      @extend %bg-neutral-0;
      .briefing-wrap {
        margin-top: var(--space-16);
        .text {
          overflow-x: auto;
        }
      }
    }
  }
  .title {
    letter-spacing: -.048rem;
    width: 30%;
    flex-shrink: 0;
    @extend %title-40;
    @extend %mb-8
  }
  p {
    @extend %mb-2;
    @extend %body-l;
    &::first-line {
      @extend %body-lb;
    }
  }
  .card-btn {
    @extend %mt-10;
  }
  .btn-download {
    width: fit-content;
    padding-inline: var(--space-4);
  }
  //map style
  #scheduleMap {
    height: 27rem;
    @extend %w-100;
    @extend %border-radius-m;
  }
  .schedule {
    &-item {
      overflow-x: auto;
    }
  }
}
.schedule-page {
  .waf-row-event {
    border-radius: var(--space-4) var(--space-4) 0 0;
    @include bgImg("cssimages/related-event-bg-mob.png");
    &::before {
      border-radius: var(--space-4) var(--space-4) 0 0;
    }
    .waf-event-strip {
      .title:before {
        @extend %d-none;
      }
    }
  }
  .waf-event-strip {
    .head-wrap {
      flex-direction: row;
      @extend %flex-sb-c;
      @extend %mb-4;
      @extend %mr-4;
    }
    .title {
      @extend %mb-0;
      @extend %body-mb;
    }
    .head-tab {
      position: static;
      @extend %m-0;
      li a {
        max-width: unset;
        color: var(--neutral-5);
        @extend %bg-transparent;
        @extend %p-0;
        @extend %neutral-0;
        &:hover {
          color: var(--neutral-20);
        }
        &:active {
          color: var(--neutral-0);
        }
        &:disabled {
          color: var(--neutral-40);
        }
        &:after {
          @include icon(arrow-right, 19);
          @extend %ml-2;
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next,
    .swiper-pagination {
      @extend %d-none;
    }
  }
  .waf-component.waf-event-strip {
    @extend %pt-0;
  }
}
@include mq(col-tablet) {
  .waf-schedule {
    padding-block: var(--space-20) var(--space-18);
    .waf-head {
      margin-bottom: 0;
    }
    .waf-body {
      grid-template-columns: 70% auto;
      padding-top: var(--space-4);
    }
    .waf-content-wrapper {
      padding: var(--space-20) var(--container-white-space);
      border-radius: var(--border-radius-l);
      .schedule-wrap,
      .briefing-wrap {
        display: flex;
        gap: var(--space-2);
      }
    }
    p {
      margin-bottom: var(--space-4);
    }
    .schedule-content {
      width: 70%;
    }
  }
  .schedule-page {
    .waf-row-event {
      border-radius: var(--space-10) var(--space-10) 0 0;
      @include bgImg("cssimages/related-event-bg-web.png");
      &::before {
        border-radius: var(--space-10) var(--space-10) 0 0;
      }
      .layout-wrapper {
        padding-top: 16rem;
      }
    }
    .waf-event-strip {
      .head-wrap {
        margin-bottom: var(--space-10);
        margin-right: 0;
      }
      .title {
        font-size: 2.4rem;
      }
    }
    .waf-component.waf-event-strip {
      padding-bottom: 16rem;
    }
  }
}